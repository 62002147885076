<template>
  <div class="w-full mb-2">
    <div v-if="type == 'checkbox'" class="flex items-start mt-4">
      <input
        type="checkbox"
        class="bg-gray-200 rounded border focus:border-gray-600 mr-1"
        :id="id"
        :checked="modelValue == (value || true)"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.checked && (value || true))"
      />
      <label
        class="inline-block uppercase tracking-wide text-gray-700 text-xs font-bold cursor-pointer"
        :for="id"
        :class="{ 'sr-only': hideLabel }"
        >{{ label }}
      </label>
    </div>
    <label
      v-if="type !== 'checkbox'"
      class="inline-block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 cursor-pointer"
      :for="id"
      :class="{ 'sr-only': hideLabel }"
    >
      {{ label }}
    </label>
    <input
      v-if="
        !type ||
        type === 'number' ||
        type === 'date' ||
        type === 'time' ||
        type === 'text' ||
        type === 'email' ||
        type === 'file'
      "
      :type="type || 'text'"
      :disabled="disabled"
      class="block w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none"
      :value="value || modelValue"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event)"
      :id="id"
      :accept="accept"
      :placeholder="placeholder"
    />
    <textarea
      v-if="type === 'textarea'"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows || 1"
      class="w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none"
      :value="value || modelValue"
      @input="$emit('input', $event.target.value)"
      :id="id"
    />
    <select
      @input="$emit('input', $event.target.value)"
      v-else-if="type === 'select'"
      class="block w-full bg-gray-200 rounded text-md text-gray-500 text-md rounded p-2"
      :class="[error && 'ring-1 ring-red-500']"
      :disabled="!options || disabled"
      :id="id"
      @change="$emit('change')"
    >
      <option :value="''" v-if="nullable || nullOption">
        {{ nullOption }}
      </option>
      <option
        v-for="option in listOptions"
        :value="option.value"
        :key="String(option.value)"
        :selected="value === option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <multi-select
      v-else-if="type == 'multi'"
      :value="value"
      @input="$emit('input', $event)"
      @search-change="$emit('search-change', $event)"
      :options="options"
      :multiple="multiple"
      :placeholder="placeholder"
      :disabled="disabled"
      :mode="mode"
      :limit="Number(limit)"
      searchable
    />
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from "vue";
import MultiSelect from "./MultiSelect.vue";
import { defineEmits } from "vue";

const props = defineProps([
  "modelValue",
  "label",
  "options",
  "type",
  "value",
  "multiple",
  "placeholder",
  "nullable",
  "nullOption",
  "disabled",
  "hideLabel",
  "error",
  "rows",
  "limit",
  "mode",
  "accept",
]);

const emit = defineEmits(["update:modelValue", "input", "change"]);

const id = computed(() => props.label.replace(/^[a-zA-Z0-9]/, "_"));

const listOptions = computed(() => {
  if (!props.options) return null;
  if (props.options.length < 1) return props.options;
  if (props.options[0].value || props.options[0].label) return props.options;

  return props.options.map((o) => ({
    value: o,
    label: o,
  }));
});

function selectFirstOptionIfNeeded() {
  if (
    props.type === "select" &&
    props.options?.length &&
    !props.nullable &&
    !props.nullOption &&
    !props.options.find((o) => o.value == props.modelValue)
  ) {
    emit("update:modelValue", props.options[0].value);
  }
}

// if not nullable and no option is selected, select the first option
watch(props, selectFirstOptionIfNeeded);
onMounted(selectFirstOptionIfNeeded);
</script>
