<template>
  <div class="flex-column h-screen justify-between gap-5">
    <div class="flex flex-row gap-5 ml-5">
      <generic-filters :filter="filter" @resetFilters="resetFilters">
        <form-field label="Zoek adres" type="text" v-model="search" class="tw-w-[250px] tw-mr-5" />

        <form-field
          label="Status"
          placeholder="Alle statussen"
          :options="statusOptions"
          mode="tags"
          type="multi"
          v-model="filter.statuses"
        />

        <form-field
          label="Woco"
          nullOption="All wocos"
          :options="wocos"
          mode="tags"
          type="multi"
          v-model="filter.wocos"
        />

        <form-field
          label="Groupen"
          placeholder="Alle groepen"
          :options="groupsOptions"
          mode="tags"
          type="multi"
          v-model="filter.groups"
        />

        <form-field
          label="Project jaar"
          :options="yearOptions"
          type="select"
          v-model="filter.year"
          placeholder="Alle jaren"
        />

        <form-field
          label="Projecten"
          :options="projects"
          mode="tags"
          type="multi"
          v-model="filter.projects"
          placeholder="Alle projecten"
        />
      </generic-filters>
      <div class="w-full flex gap-5">
        <div class="m-2 flex-auto flex-col">
          <locations-list
            :locations="locations"
            :has-next-page="hasNextPage"
            :is-loading="isLoading"
            :numberOfLocations="numberOfLocations"
            @fetchNextPage="fetchNextPage()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { watchDebounced } from "@vueuse/core";
import { useQuery, useInfiniteQuery } from "@tanstack/vue-query";
import fetchData from "@/util/fetch";
import GenericFilters from "@/components/shared/GenericFilters.vue";
import FormField from "@/components/shared/FormField.vue";
import LocationsList from "@/components/LocationsList.vue";

const search = ref("");
const filter = reactive({
  projects: [],
  groups: [],
  statuses: [],
  wocos: [],
  year: null,
  query: "",
});

const nextYear = new Date().getFullYear() + 1;
const yearOptions = Array.from({ length: nextYear - 2018 + 1 }, (_, i) => 2018 + i);

const getGroups = () => fetchData(`/groups/api/v1/group`);
const groupQuery = useQuery({
  queryKey: ["groups"],
  queryFn: getGroups,
});
const groups = computed(() => {
  if (filter.wocos.length && groupQuery.data.value) {
    return Array.from(
      new Set(groupQuery.data.value.data.filter((group) => filter.wocos.includes(group.woco)))
    );
  }

  return (groupQuery.data.value && Array.from(new Set(groupQuery.data.value.data))) || [];
});

const groupsOptions = computed(() =>
  groups.value.map((group) => ({ value: group.id, label: group.name }))
);

const getProjects = () => fetchData("/config/api/v1/projects/?view=full");
const projectsQuery = useQuery({
  queryKey: ["projects"],
  queryFn: getProjects,
});

const projects = computed(() => {
  if (filter.wocos.length && projectsQuery.data.value) {
    return projectsQuery.data.value?.data
      .filter((prj) => filter.wocos.includes(prj.woco))
      .map((prj) => ({ value: prj.id, label: prj.name }));
  }
  return projectsQuery.data.value?.data.map((prj) => ({ value: prj.id, label: prj.name }));
});

const getWocos = () => fetchData("/config/api/v1/wocos/?view=full");
const wocosQuery = useQuery({
  queryKey: ["wocos"],
  queryFn: getWocos,
});
const wocos = computed(() => wocosQuery.data.value?.data.map((w) => w.name));

const statuses = {
  finished: "afgerond",
  created: "te plannen",
  design: "ontwerp",
  sales: "werving",
  inspection: "schouw",
  installation: "installatie",
  cancelled: "geannuleerd",
};

const statusOptions = Object.entries(statuses).map(([key, value]) => ({
  value: key,
  label: value,
}));

const resetFilters = () => {
  Object.assign(filter, {
    projects: [],
    groups: [],
    statuses: [],
    wocos: [],
    query: "",
  });
};

const perPage = 30;
const getLocations = ({ pageParam = 1 }) => {
  const { statuses, projects, groups, wocos } = filter;

  const urlParams = new URLSearchParams({
    view: "list",
    offset: (pageParam - 1) * perPage,
    limit: perPage,
    query: search.value,
  });
  if (projects) {
    for (const p of projects) {
      urlParams.append("project_ids", p);
    }
  }
  if (groups) {
    for (const g of groups) {
      urlParams.append("group_ids", g);
    }
  }
  if (wocos) {
    for (const w of wocos) {
      const woco = wocosQuery.data?.value?.data.find((woco) => woco.name === w)?.shortname || w;
      urlParams.append("woco_names", woco);
    }
  }
  if (statuses) {
    for (const s of statuses) {
      urlParams.append("status", s);
    }
  }
  if (filter.year) {
    urlParams.append("year", filter.year);
  }
  return fetchData(`/tickets/api/v1/locations?${urlParams}`);
};

watchDebounced(
  search,
  () => {
    filter.query = search.value;
  },
  {
    debounce: 300,
  }
);

const {
  data: locationsQuery,
  isLoading,
  fetchNextPage,
  hasNextPage,
} = useInfiniteQuery({
  queryKey: computed(() => ["locationAddresses", filter]),
  keepPreviousData: true,
  queryFn: getLocations,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data.length === perPage ? allPages.length + 1 : undefined,
});

const numberOfLocations = computed(() => locationsQuery?.value?.pages[0].total);
const locations = computed(() => locationsQuery?.value?.pages.flatMap((p) => p.data) || []);
</script>
