<template>
  <div
    v-if="message"
    class="fixed bottom-20 left-1/2 transform -translate-x-1/2 p-4 bg-gray-800 rounded text-white z-50"
    :class="{
      'bg-green-800': type === 'success',
      'bg-red-800': type === 'error',
      'bg-blue-800': type === 'info',
    }"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    type: {
      type: String,
      default: "info",
    },
  },
};
</script>
