<template>
  <tr class="m-2">
    <td class="align-middle flex flex-start">
      <input
        type="checkbox"
        :checked="selectedAddresses.includes(location.location_id)"
        @change="$emit('toggleSelection', location.location_id)"
        class="mx-auto"
      />
      <PencilIcon size="20" @click="showEditModal = true" />
    </td>
    <td class="px-2">
      {{ location.woco_id }}
    </td>
    <td class="px-2">
      {{ address }}
    </td>
    <td class="px-1 rounded-lg flex justify-center" :class="getStatusClass(location)">
      {{ checkStatus(location) }}
    </td>

    <td class="px-5">
      <div class="flex flex-start space-x-4 text-gray-700">
        <button
          v-if="location.status === 'finished'"
          class="bg-gray-500 rounded px-1 text-white flex justify-center items-center align-middle pointer"
          @click.prevent="fetchAsbuilt"
        >
          <DocumentTextIcon size="20" />
          <div class="px-1">Asbuilt</div>
        </button>
      </div>
    </td>

    <notification-message :message="notification.message" :type="notification.type" />
    <generic-modal
      v-if="showEditModal"
      @close="showEditModal = false"
      @submit="showEditModal = false"
      :title="`Edit client ${location.address.street} ${location.address.house_number}`"
    >
      <FormField
        label="Periode"
        type="select"
        v-model="showEditModal"
        :options="['1 week', '2 weken', '1 maand', '3 maanden', '1 jaar']"
      />
    </generic-modal>
  </tr>
</template>

<script setup>
import { ref, computed } from "vue";
import FormField from "@/components/shared/FormField.vue";
import {
  DocumentTextIcon,
  PencilIcon,
  PencilAltIcon,
  PhotographIcon,
} from "@vue-hero-icons/outline";
import fetchData from "@/util/fetch";
import NotificationMessage from "./NotificationMessage.vue";
import GenericModal from "@/components/shared/GenericModal.vue";

const props = defineProps({
  location: {
    type: Object,
    required: true,
  },
  selectedAddresses: {
    type: Array,
    required: true,
  },
  lastSelectedLocation: {
    type: String,
    required: true,
  },
});

const showEditModal = ref(false);
const notification = ref({ message: "", type: "" });
const address = computed(
  () =>
    props.location.address.street +
    " " +
    props.location.address.house_number +
    ", " +
    props.location.address.postal_code +
    ", " +
    props.location.address.city
);

const checkStatus = (location) => {
  const status = location.status;
  switch (status) {
    case "finished":
      return "afgerond";
      break;
    case "design":
      return "ontwerp";
      break;
    case "sales":
      return "werving";
      break;
    case "inspection":
      return "schouw";
      break;
    case "installation":
      return "installatie";
      break;
    case "cancelled": {
      const inspection_status = location.task_statuses?.inspection.status;
      const sales_status = location.task_statuses?.sales.status;
      const design_status = location.task_statuses?.design.status;
      const installation_status = location.task_statuses?.installation.status;
      if (inspection_status === "cancelled") {
        return "schouw geannuleerd";
      } else if (sales_status === "cancelled") {
        return "werving geannuleerd";
      } else if (design_status === "cancelled") {
        return "ontwerp geannuleerd";
      } else if (installation_status === "cancelled") {
        return "installatie geannuleerd";
      } else {
        return "geannuleerd";
      }
      break;
    }
    default:
      return "geen status";
      break;
  }
};

const getStatusClass = (location) => {
  const status = checkStatus(location);
  if (status.includes("geannuleerd")) {
    return "bg-pink-200";
  } else if (status === "afgerond") {
    return "bg-emerald-200";
  } else {
    return "bg-blue-200";
  }
};

const fetchAsbuilt = async () => {
  try {
    console.log("fetching asbuilt data, for test Broekweg 95 2161XC");
    const result = await fetchData(
      `/tickets/api/v1/locations/${props.location.location_id}/asbuilt`
    );
    window.open(result.data.url, "_blank");
  } catch (error) {
    notification.value = {
      message: `Failed to fetch asbuilt data: ${error.message}`,
      type: "error",
    };
  }
};
</script>
