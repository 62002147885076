<template>
  <div class="flex flex-col sticky top-0 h-screen">
    <div class="font-bold">
      <div class="bg-white drop-shadow-xl md:p-3 rounded-b-lg p-3">
        <div class="flex justify-between">
          <div v-if="show" class="mb-3 flex">
            <h1 class="font-bold tracking-wider uppercase text-gray-500">Filters</h1>
          </div>
          <button @click="show = !show" class="flex uppercase text-gray-500">
            <span v-if="show" class="w-[20px]">
              <i class="fas fa-caret-left" aria-hidden="true"></i>
            </span>
            <span v-else class="w-[20px]">
              <i class="fas fa-caret-right" aria-hidden="true"></i>
            </span>
          </button>
        </div>

        <div v-if="show" class="w-[300px]">
          <slot></slot>
          <div class="flex justify-between">
            <button class="bg-gray-500 rounded p-1 text-white mt-3" @click="$emit('resetFilters')">
              Reset filters
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  filter: {
    type: Object,
    required: true,
  },
});

const show = ref(true);
</script>
