<template>
  <div v-if="isLoading" class="flex gap-5 items-center">
    <div
      class="border-gray-300 m-5 h-10 w-10 animate-spin rounded-full border-8 border-t-green-600"
    />
    Laden...
  </div>
  <div v-else>
    <div class="flex w-full justify-between">
      <div class="my-4">
        {{ numberOfLocations }} resultaten gevonden / {{ selectedAddresses.length }} geselecteeerd
        {{ selectedAddresses }}
      </div>
      <div v-if="selectedAddresses.length > 1">
        <button class="bg-green-700 rounded px-2 py-1 text-white mt-3" @click="downloadFolder">
          Download Asbuilt
        </button>
      </div>
    </div>
    <div class="flex gap-2 flex-cols items-center mb-2 justify-between">
      <table class="w-full border-separate border-spacing-y-3" v-if="locations">
        <thead>
          <tr class="leading-10 bg-gray-200 text-left">
            <th class="px-2 rounded-l-lg">
              <input
                type="checkbox"
                @change="selectAllAddresses"
                :checked="selectedAddresses.length === locations.length"
              />
            </th>

            <th class="px-2">ID</th>
            <th class="px-2">Adres</th>
            <th class="px-2">Status</th>
            <th class="px-2 rounded-r-lg">Download acties</th>
          </tr>
        </thead>
        <tbody>
          <location-list-item
            v-for="location in locations"
            :key="location.id"
            :locations="locations.map((location) => location.location_id)"
            :location="location"
            :selectedAddresses="selectedAddresses"
            :lastSelectedLocation="lastSelectedLocation"
            @toggleSelection="toggleSelection"
            @toggle="toggleAddressSelection"
            class="hover:bg-gray-100"
          />
        </tbody>
      </table>
    </div>
    <notification-message :message="notification.message" :type="notification.type" />
    <div class="flex" v-show="hasNextPage">
      <div class="mx-auto mt-3 mb-5">
        <button
          @click="$emit('fetchNextPage')"
          class="bg-blue-400 rounded p-1 disabled:bg-gray-100"
        >
          Laad meer...
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import NotificationMessage from "./NotificationMessage.vue";
import LocationListItem from "./LocationListItem.vue";
import { useKeyModifier } from "@vueuse/core";

const props = defineProps({
  locations: {
    type: Array,
  },
  hasNextPage: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
  },
  numberOfLocations: {
    type: Number,
  },
});

defineEmits(["fetchNextPage"]);
const selectedAddresses = ref([]);
const lastSelectedLocation = ref("");
const notification = ref({ message: "", type: "" });
const shiftState = useKeyModifier("Shift");

const selectAllAddresses = (event) => {
  if (event.target.checked) {
    selectedAddresses.value = props.locations.map((location) => location.location_id);
  } else {
    selectedAddresses.value = [];
  }
};

const toggleAddressSelection = (addressId) => {
  if (selectedAddresses.value.includes(addressId)) {
    selectedAddresses.value = selectedAddresses.value.filter((id) => id !== addressId);
  } else {
    lastSelectedLocation.value = addressId;
    selectedAddresses.value = [...selectedAddresses.value, addressId];
  }
};

const downloadFolder = () => console.log("Download folder voor:", selectedAddresses.value);

const toggleSelection = (id) => {
  const locationsIds = props.locations.map((location) => location.location_id);
  if (shiftState.value === true && lastSelectedLocation.value !== null) {
    const startIndex = locationsIds.indexOf(lastSelectedLocation.value);
    const endIndex = locationsIds.indexOf(id);
    let addressesToToggle = [];

    if (startIndex < endIndex) {
      addressesToToggle = locationsIds.slice(startIndex + 1, endIndex + 1);
    } else {
      addressesToToggle = locationsIds.slice(endIndex, startIndex).reverse();
    }
    for (const address of addressesToToggle) {
      toggleAddressSelection(address);
    }
    return;
  }
  toggleAddressSelection(id);
};
</script>
