<template>
  <header class="bg-ecorus">
    <div class="flex flex-row">
      <div class="flex p-3 items-center shrink-0">
        <figure style="height: 35px">
          <img src="../assets/EcorusLogo-wit.svg" style="height: 100%" />
        </figure>
      </div>
      <div class="flex">
        <template v-for="screen in screens">
          <router-link
            :disabled="!enabled"
            v-if="!!user.permissions[screen.permission]"
            :key="screen.name"
            :to="{ name: screen.name }"
            tag="button"
            :class="[
              'p-2 text-white hover:underline',
              { 'font-bold': selectedScreen === screen.name },
            ]"
          >
            {{ screen.display_name ? screen.display_name : screen.name }}
          </router-link>
        </template>
      </div>
      <div class="grow"></div>
      <div class="flex justify-content-end items-center gap-2" v-if="isLoggedIn">
        <div class="flex items-center gap-2 mr-3">
          <p class="mb-0 mx-3">{{ user.name }}</p>
          <e-button class="btn btn-secondary" @click="logout" color="gray"> Uitloggen </e-button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import * as auth from "@/common/auth";
import * as requests from "@/common/requests";
import EButton from "@/components/EButton.vue";

export default {
  name: "AppHeader",
  props: {
    selectedScreen: String,
    enabled: Boolean,
  },
  data() {
    return {
      screens: [],
    };
  },
  computed: {
    ...mapState(["selectedWoco", "userEmail", "isLoggedIn", "user"]),
    currentRoute() {
      return this.$route.name;
    },
  },
  components: {
    EButton,
  },
  methods: {
    async logout() {
      this.$events.emit("interaction", {
        type: "submit",
        context: "auth",
        value: "logout",
      });
      const userLoggedOut = await auth.logout();
      if (userLoggedOut) {
        this.$router.push({ name: "Login" });
      }
    },
    async getScreens() {
      const screens = await requests.getScreenPermissions();
      console.log("screens", screens);
      this.screens = screens.data;
    },
  },
  created() {
    this.getScreens();
  },
};
</script>

<style>
.header-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn.btn-navigation {
  color: lightgray;
}

.btn.btn-navigation:not(:disabled):hover {
  color: white;
}

.btn.btn-navigation:disabled:hover {
  color: lightgray;
}

.btn.btn-navigation.selected-screen {
  color: white;
  font-weight: bold;
}

.btn.btn-navigation.selected-screen:hover {
  color: white;
  font-weight: bold;
}
</style>
