var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full mb-2"},[(_vm.type == 'checkbox')?_c('div',{staticClass:"flex items-start mt-4"},[_c('input',{staticClass:"bg-gray-200 rounded border focus:border-gray-600 mr-1",attrs:{"type":"checkbox","id":_setup.id,"disabled":_vm.disabled},domProps:{"checked":_vm.modelValue == (_vm.value || true)},on:{"input":function($event){_vm.$emit('update:modelValue', $event.target.checked && (_vm.value || true))}}}),_c('label',{staticClass:"inline-block uppercase tracking-wide text-gray-700 text-xs font-bold cursor-pointer",class:{ 'sr-only': _vm.hideLabel },attrs:{"for":_setup.id}},[_vm._v(_vm._s(_vm.label)+" ")])]):_vm._e(),(_vm.type !== 'checkbox')?_c('label',{staticClass:"inline-block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 cursor-pointer",class:{ 'sr-only': _vm.hideLabel },attrs:{"for":_setup.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(
      !_vm.type ||
      _vm.type === 'number' ||
      _vm.type === 'date' ||
      _vm.type === 'time' ||
      _vm.type === 'text' ||
      _vm.type === 'email' ||
      _vm.type === 'file'
    )?_c('input',{staticClass:"block w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none",attrs:{"type":_vm.type || 'text',"disabled":_vm.disabled,"id":_setup.id,"accept":_vm.accept,"placeholder":_vm.placeholder},domProps:{"value":_vm.value || _vm.modelValue},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event)}}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"w-full bg-gray-200 rounded text-md text-gray-500 p-2 focus:bg-white border focus:border-gray-600 appearance-none focus:outline-none",attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"rows":_vm.rows || 1,"id":_setup.id},domProps:{"value":_vm.value || _vm.modelValue},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}):(_vm.type === 'select')?_c('select',{staticClass:"block w-full bg-gray-200 rounded text-md text-gray-500 text-md rounded p-2",class:[_vm.error && 'ring-1 ring-red-500'],attrs:{"disabled":!_vm.options || _vm.disabled,"id":_setup.id},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change')}}},[(_vm.nullable || _vm.nullOption)?_c('option',{domProps:{"value":''}},[_vm._v(" "+_vm._s(_vm.nullOption)+" ")]):_vm._e(),_vm._l((_setup.listOptions),function(option){return _c('option',{key:String(option.value),domProps:{"value":option.value,"selected":_vm.value === option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2):(_vm.type == 'multi')?_c(_setup.MultiSelect,{attrs:{"value":_vm.value,"options":_vm.options,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"mode":_vm.mode,"limit":Number(_vm.limit),"searchable":""},on:{"input":function($event){return _vm.$emit('input', $event)},"search-change":function($event){return _vm.$emit('search-change', $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }