<template>
  <div>
    <div>
      <h3 class="text-4xl">Nieuwe klanten uploaden via Excel</h3>
    </div>
    <div v-if="user.wocos.length > 1" class="my-3">
      Gekozen woco:
      <DropDown class="my-1" />
    </div>

    <div>
      <div v-if="clients.length > 0" class="flex flex-col gap-2">
        <Alert :alerts="alertMessages" />

        <div>Totaal {{ clients.length }} addressen gevonden.</div>

        <div class="flex flex-row gap-2 items-center">
          <div>Actie:</div>
          <div>
            <select v-model="action" class="p-2">
              <option value="create">Nieuwe klanten aanmaken</option>
              <option value="update">Bestaande klanten updaten</option>
            </select>
          </div>
        </div>

        <div class="italic" v-if="action === 'update'">
          Bij het updaten van gegevens wordt gekeken of de klantnaam is veranderd. Is dit het geval,
          dan worden alle telefoonnummers en emailadressen vervangen door de adressen in de Excel.
          Is de klantnaam gelijk, dan worden de telefoonnummers en emailadressen samengevoegd.
        </div>

        <div class="flex flex-col items-start gap-2">
          <div class="bg-red-100 p-2 rounded" v-if="validation.filter((v) => !v.success).length">
            Er zijn {{ validation.filter((v) => !v.success).length }} adressen met validatiefouten
          </div>
          <div
            class="bg-red-100 p-2 rounded"
            v-if="action === 'create' && duplicates.some((d) => d.duplicate)"
          >
            Er zijn {{ duplicates.filter((d) => d.duplicate).length }} dubbele addressen gevonden
          </div>
          <div
            class="bg-red-100 p-2 rounded"
            v-if="action === 'update' && duplicates.some((d) => !d.duplicate)"
          >
            Er zijn {{ duplicates.filter((d) => !d.duplicate).length }} adressen gevonden die nog
            niet bestaan
          </div>
          <div class="bg-red-100 p-2 rounded" v-if="incorrect_addresses.some((i) => i?.message)">
            Er zijn {{ incorrect_addresses.filter((i) => i?.message).length }} foutieve adressen
            gevonden
          </div>
        </div>

        <div v-if="!valid">
          Als bovenstaande problemen zijn opgelost kun je de gegevens importeren.
        </div>
        <div v-else>
          <e-button color="green" @click="startImport" :loading="importIsLoading">
            Gegevens importeren
          </e-button>
        </div>

        <div v-if="importResult?.length === clients.length">Import voltooid.</div>
        <div v-else-if="importResult?.length > 0">
          {{ importResult.length }}/{{ clients.length }} voltooid.
        </div>

        <div class="mb-2">
          <button
            type="button"
            v-if="!details"
            @click="details = true"
            class="p-1 rounded bg-blue-300 text-white"
          >
            Bekijk details per adres
          </button>
          <button
            type="button"
            v-else
            @click="details = false"
            class="p-1 rounded bg-blue-300 text-white"
          >
            Verberg details per adres
          </button>
        </div>

        <ExcelImportTable
          v-if="details"
          :clients="clients"
          :validation="validation"
          :duplicates="duplicates"
          :incorrect_addresses="incorrect_addresses"
          :action="action"
          :result="importResult"
        />
      </div>
      <div v-else>
        <Alert :alerts="alertMessages" class="float-right" />
        <div class="flex flex-col">
          <div>Kies een Excel bestand:</div>
          <DragAndDrop :fileChange="fileChange" />
        </div>
      </div>
    </div>

    <Modal v-if="isFetching">
      <Spinner />
    </Modal>
  </div>
</template>

<script setup>
import readXlsxFile from "read-excel-file";

import Spinner from "@/components/Spinner.vue";
import Modal from "@/components/Modal.vue";
import Alert from "@/components/Alert.vue";
import DropDown from "@/components/DropDown.vue";
import DragAndDrop from "@/components/DragAndDrop.vue";
import ClientFormatter from "@/common/ClientFormatter";
import * as requests from "@/common/requests";
import ExcelImportTable from "@/components/ExcelImportTable.vue";
import { ref, computed } from "vue";
import store from "@/store";
import { z } from "zod";
import EButton from "@/components/EButton.vue";
import { useMutation } from "@tanstack/vue-query";

const schema = z.object({
  household: z.object({
    street: z.string().min(3).max(200),
    house_number: z.string().regex(/^[a-zA-Z0-9-( )]+$/),
    city: z.string().min(3).max(200),
    postal_code: z.string().regex(/^[0-9]{4}[A-Z]{2}$/),
  }),
  people: z
    .array(
      z.object({
        emails: z.array(
          z.object({
            address: z.string().email(),
          })
        ),
        phones: z.array(
          z.object({
            number_text: z.string().regex(/^[0-9+\- ]{8,13} ?[a-zA-Z-( )]{0,30}$/),
          })
        ),
      })
    )
    .optional(),
});

const { user } = store.state;
const action = ref("create");
const isFetching = ref(false);
const duplicates = ref([]);
const incorrect_addresses = ref([]);
const alertMessages = ref([]);
const clients = ref([]);
const validation = computed(() =>
  clients.value.map((c) => {
    const result = schema.safeParse(c);
    return result;
  })
);
const details = ref(false);
const importResult = ref(null);

const valid = computed(() => {
  if (validation.value.filter((v) => !v.success).length !== 0) {
    return false;
  }

  if (incorrect_addresses.value.filter((i) => i.message).length !== 0) {
    return false;
  }

  if (action.value === "create") {
    return duplicates.value.filter((d) => d.duplicate).length === 0;
  } else {
    // update, everything should be duplicated
    return duplicates.value.filter((d) => !d.duplicate).length === 0;
  }
});

function isExcelFile(file) {
  const excelFileExtensions = ["xlsx", "xls"];
  return excelFileExtensions.includes(file.name.split(".").pop());
}

function fileChange({ target }) {
  isFetching.value = true;

  const file = target.files[0];
  if (isExcelFile(file)) {
    readFileData(file);
  } else {
    alertMessages.value = [
      { message: "Zorg ervoor dat het ingediende bestand een excel bestand is", type: "danger" },
    ];
    isFetching.value = false;
  }
}

function toClient(data) {
  return new ClientFormatter(data).client;
}

async function readFileData(file) {
  try {
    const rows = await readXlsxFile(file);

    const fixedRows = rows
      .map((row) => row.map((cell) => (cell ? cell.toString() : "")))
      .filter((r) => r[1] !== "" && r[2] !== "");
    // filter checks if the id, address, and client name are filled in
    // if they all empty - we assume there is nothing to upload from this line
    const header = fixedRows[0];
    const mandatoryColumns = ["id", "address", "postcode", "city"];

    if (!mandatoryColumns.every((column) => header.includes(column))) {
      alertMessages.value = [{ message: "Ongeldig excel bestandsformaat", type: "danger" }];
      isFetching.value = false;
      return;
    }
    alertMessages.value = [];

    const formattedClients = fixedRows.slice(1).map(toClient);
    clients.value = formattedClients;

    const data = formattedClients.map((c) => ({
      address: `${c.household.street} ${c.household.house_number} ${c.household.postal_code}`,
      postal_code: c.household.postal_code,
      house_number: c.household.house_number,
      id_woco: c.household.id_woco,
    }));
    const response = await fetch("/api/client/operation/check/", {
      method: "POST",
      body: JSON.stringify({
        clients: data,
        woco: store.state.selectedWoco,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      alertMessages.value.unshift({
        message: "Probleem met ophalen van dubbele addressen",
        type: "danger",
      });
      isFetching.value = false;
      return;
    }
    duplicates.value = (await response.json()).data;
    console.log(duplicates.value);

    const address_data = formattedClients.map((c) => ({
      postal_code: c.household.postal_code,
      house_number: c.household.house_number,
      city: c.household.city,
      street: c.household.street,
    }));

    incorrect_addresses.value = (await requests.getPostcodeInfo(address_data)).data;

    isFetching.value = false;
  } catch (err) {
    alertMessages.value.unshift({
      message: "Probleem met het klaarzetten van adressen",
      type: "danger",
    });
    console.error(err);
    isFetching.value = false;
  }
}

function createPayload(context, data) {
  return {
    type: "create",
    context,
    data,
  };
}

function removePayload(context, data) {
  return {
    type: "remove",
    context,
    data,
  };
}

function updatePayload(context, data) {
  return {
    type: "update",
    context,
    data,
  };
}

function createPerson(person) {
  const operations = [];
  operations.push(createPayload("person", person));

  person.phones.forEach((phone) =>
    operations.push(createPayload("phone", { ...phone, person_name: person.name }))
  );
  person.emails.forEach((email) =>
    operations.push(createPayload("email", { ...email, person_name: person.name }))
  );

  return operations;
}

function createClientsPayload(clients) {
  console.log("createClientsPayload");
  return clients.map((client) => {
    let operations = [];
    const { household, people } = client;

    operations.push(createPayload("household", household));
    operations.push(...people.flatMap((person) => createPerson(person)));

    return {
      address: `${household.street} ${household.house_number} ${household.postal_code}`,
      operations,
    };
  });
}

function updateClientsPayload(clients, duplicates) {
  console.log("updateClientsPayload");
  return clients.map((client, index) => {
    let operations = [];
    const { household, people } = client;
    const existing = duplicates[index]?.duplicate;
    const person = people[0];

    if (!existing)
      return {
        address: `${household.street} ${household.house_number} ${household.postal_code}`,
        operations,
      };

    // update woco id if existing woco id is empty or different from current
    if (
      String(household.id_woco).trim() !== "" &&
      String(existing.household.id_woco).trim() !== String(household.id_woco).trim()
    ) {
      existing.household.id_woco = household.id_woco;
      operations.push(updatePayload("household", existing.household));
    }

    // if person data is not set in the excel, skip those steps
    if (!person)
      return {
        address: `${household.street} ${household.house_number} ${household.postal_code}`,
        operations,
      };
    const existingPerson = existing.people.find((p) => p.name === person.name);

    // if the person name exists -> add emails/phone numbers that do not exist yet
    if (existingPerson) {
      operations = operations.concat(
        ...person.phones
          // remove existing phones from the list
          .filter(
            (phone) => !existingPerson.phones.some((p) => p.number_text === phone.number_text)
          )
          // add the remaining as new phones for this existing person
          .map((phone) => createPayload("phone", { ...phone, person_id: existingPerson.id })),
        ...person.emails
          // remove existing emails from the list
          .filter((email) => !existingPerson.emails.some((e) => e.address === email.address))
          // add the remaining as new emails for this existing person
          .map((email) => createPayload("email", { ...email, person_id: existingPerson.id }))
      );
    }

    // if the person name does not exist -> remove existing people, add person
    else {
      operations = operations.concat(
        // remove existing people
        ...existing.people.map((p) => removePayload("person", { person_id: p.id })),
        // add person with phone number
        ...createPerson(person)
      );
    }

    return {
      address: `${household.street} ${household.house_number} ${household.postal_code}`,
      operations,
    };
  });
}

const batchSize = 50;
function chunks(list, size) {
  const result = [];
  for (let i = 0; i < list.length; i += size) {
    result.push(list.slice(i, i + size));
  }
  return result;
}

const { mutateAsync, isLoading: importIsLoading } = useMutation({
  mutationFn: async (clients) => {
    const payload = {
      clients,
      woco: store.state.selectedWoco,
    };
    const result = await fetch("/api/client/operation/update/", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.ok) {
      return result.json();
    } else {
      throw await result.json();
    }
  },
  onSuccess: (data) => {
    importResult.value = (importResult.value || []).concat(data.data.clients);
  },
  onError: (data) => {
    console.error(data);
    alertMessages.value = [{ message: data?.message, type: "danger" }];
  },
});

async function startImport() {
  console.log(action.value);
  let updates =
    action.value === "update"
      ? updateClientsPayload(clients.value, duplicates.value)
      : createClientsPayload(clients.value);

  const sets = chunks(updates, batchSize);
  for (const clients of sets) {
    console.log(clients);
    await mutateAsync(clients);
  }
}
</script>
