export async function getClientsForWoco(woco, projects, group = null) {
  let response;
  let json;
  let message = "Success";
  let status = "OK";
  const acceptedResponses = [200, 201, 204, 206];

  try {
    const url = `/api/woco/${woco}/clients/`;

    let body = {
      projects,
    };

    if (group) {
      body = { ...body, group };
    }

    const options = {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    };

    response = await fetch(url, options);
    json = await response.json();
    if (!acceptedResponses.includes(response.status)) {
      message = "Service niet beschikbaar. Probeer het later opnieuw.";
      status = "error";
    }
  } catch (err) {
    message = "Service niet beschikbaar. Probeer het later opnieuw.";
    status = "error";
  }
  return {
    response,
    json,
    message,
    status,
  };
}

export async function getData(url) {
  try {
    const response = await fetch(url);
    const json = await response.json();
    return { ...json, statusCode: response.status }; // data, message, status
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function postData(method, url, data) {
  try {
    const options = {
      method,
      credentials: "include",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(url, options);
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      status: "error",
      message: "Internal Server Error",
    };
  }
}

export async function postDataBlob(method, url, data) {
  let response;
  let blob;
  const message = "Success";
  const status = "OK";

  try {
    const options = {
      method,
      credentials: "include",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    };

    response = await fetch(url, options);
    blob = await response.blob();

    return {
      blob,
      message,
      status,
    };
  } catch (err) {
    return {
      message: "Server error",
      status: "error",
    };
  }
}

export function getTaskDataForClient() {
  return {
    image: "https://ca.slack-edge.com/T0GR3G0R2-U01CG5K5129-39b8d838f4da-512",
  };
}

export async function getFieldHistoryForHousehold(address) {
  let response;
  let json;
  let message = "Success";
  let status = "OK";

  try {
    response = await fetch(`/api/client/household/${address}/history/`);
    json = await response.json();
    if (response.status !== 200) {
      message = "Service niet beschikbaar. Probeer het later opnieuw.";
      status = "error";
    }
  } catch (err) {
    message = "Service niet beschikbaar. Probeer het later opnieuw.";
    status = "error";
  }
  return {
    response,
    json,
    message,
    status,
  };
}

export async function getScreenPermissions() {
  try {
    const response = await fetch("/api/screen/permissions/");
    console.log("response", response);
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getDashboardInfo(woco, projects, groups) {
  try {
    const url = `/api/woco/${woco}/dashboard/`;

    const options = {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        projects,
        groups,
      }),
    };

    const response = await fetch(url, options);

    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getContract(address, contractType, contractID, filename) {
  try {
    const response = await fetch(
      `/api/household/${address}/contract/${contractType}/${filename}/${contractID}/`
    );
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getContractBlob(address, contractType, contractID, filename) {
  try {
    const response = await fetch(
      `/api/household/${address}/contract/${contractType}/${contractID}/${filename}/downloadable/`
    );
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getAsbuilt(addressPostcode, asbuiltFilename) {
  try {
    const response = await fetch(`/api/household/${addressPostcode}/asbuilt/${asbuiltFilename}/`);
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getImage(filePath) {
  try {
    const response = await fetch(
      `api/household/images?` +
        new URLSearchParams({
          file_path: filePath,
        })
    );
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function downloadFiles(addresses, fileType) {
  try {
    const response = await fetch(`/api/household/files/${fileType}/`, {
      method: "POST",
      body: JSON.stringify(addresses),
    });
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getDocumentTemplatesForWoco(woco) {
  try {
    const response = await fetch(`/api/template/${woco}`);
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}

export async function getPostcodeInfo(address) {
  try {
    const response = await fetch(`/api/postcodes/check/`, {
      method: "POST",
      body: JSON.stringify(address),
    });
    const json = await response.json();
    return { ...json, statusCode: response.status };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      message: "Service niet beschikbaar. Probeer het later opnieuw.",
      status: "error",
    };
  }
}
